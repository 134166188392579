import { HttpErrorResponse } from '@angular/common/http';

export class ResponseError {
  public url: string | null;
  public status: number | null;
  public message: string | null;
  public stacktrace: string | null;

  constructor() {
    this.url = null;
    this.status = null;
    this.message = null;
    this.stacktrace = null;
  }

  static CreateFromHttpErrorResponse(httpErrorResponse: HttpErrorResponse) {
    let responseError = new ResponseError();

    if (httpErrorResponse.error != null && !(httpErrorResponse.error instanceof ProgressEvent)) {
      if (httpErrorResponse.error.hasOwnProperty('message')) {
        responseError.message = httpErrorResponse.error.message;
      }

      if (httpErrorResponse.error.hasOwnProperty('stacktrace')) {
        responseError.stacktrace = httpErrorResponse.error.stacktrace;
      }
    } else {
      responseError.message = httpErrorResponse.message;
    }

    responseError.status = httpErrorResponse.status;
    responseError.url = httpErrorResponse.url;

    return responseError;
  }

// constructor(message: string, detailedMessage: string) {
//     super(message);
//     this._message = message;
//     this._detailedMessage = detailedMessage;
//     Object.setPrototypeOf(this, ResponseError.prototype);
// }
}
