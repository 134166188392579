import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { HubConnectionBuilder } from '@microsoft/signalr/dist/esm/HubConnectionBuilder';
import { Observable, Subject, BehaviorSubject, Subscription, exhaustMap, from, interval, of } from 'rxjs';
import { catchError, filter, map, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const HUB_NAME = 'notificationHub';

@Injectable({
  providedIn: "root"
})
export class SignalRService {

  // private _connected: Subject<void> = new Subject<void>();
  private _reconnected: Subject<void> = new Subject<void>();
  // private _disconnected: Subject<void> = new Subject<void>();
  private _hubConnectionSubject: BehaviorSubject<HubConnection | null> = new BehaviorSubject<HubConnection | null>(null);
  private _reconnectionSubscription: Subscription | null = null;
  private _intervalToReconnect: number = 5000;
  private _disposed: boolean = false;
  // private _hubConnection: HubConnection | null = null;
  private _isConnected: boolean = false;
  private _isConnecting: boolean = false;

  // public get connected$(): Observable<void> { return this._connected.asObservable(); }
  private get reconnected$(): Observable<void> { return this._reconnected.asObservable(); }
  // public get disconnected$(): Observable<void> { return this._disconnected.asObservable(); }

  public get hubConnection$(): Observable<HubConnection | null> { return this._hubConnectionSubject.asObservable(); }
  public get hubConnection(): HubConnection | null { return this._hubConnectionSubject.value; }

  constructor() {
    this._disposed = false;
  }

  public async startConnection(): Promise<HubConnection | null> {
    const that = this;

    this._log('SignalR - connection starting...');

    await this._hubConnectionSubject.value?.stop();

    const hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.BASE_URL}/${HUB_NAME}`
        // , {
        //   accessTokenFactory: () => {
        //     return new Promise<string>((resolve, reject) => {
        //       if (that._jwtAuth.jwtToken != null) {
        //         if (that._jwtAuth.isTokenExpired()) {
        //           that._jwtAuth.refreshToken().pipe(
        //             take(1)
        //           ).subscribe(x => {
        //             resolve(x.accessToken);
        //           }, e => {
        //             that._session.logout();
        //             return reject(e);
        //           });
        //         } else {
        //           that._jwtAuth.jwtToken$.pipe(
        //             filter(x => x != null)
        //           ).subscribe(x => {
        //             resolve(x.accessToken);
        //           });
        //         }
        //       } else {
        //         resolve(null);
        //       }
        //     });
        //   }
        //})
      )
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          return this._intervalToReconnect;
          // return that.getRandomIntInclusive(1000, 30000); // provo la riconnessione in tempi randomici che vanno da 3 a 30 secondi
        }
      })
      .configureLogging(LogLevel.Error)
      .build();

    hubConnection.onreconnected(() => {
      if (!that._disposed) {
        that._log('SignalR - connection reconnected');
        that._isConnected = true;
      } else {
        that._log('SignalR - onreconnected DISPOSED');
      }
    });

    hubConnection.onclose(function (err) {
      if (!that._disposed) {
        that._log('SignalR - connection closed');
        // that._disconnected.next();
        that._isConnected = false;
        that._reconnectManually();
      } else {
        that._log('SignalR - onclose DISPOSED');
      }
    });

    hubConnection.onreconnecting(() => {
      if (!that._disposed) {
        this._log('SignalR - reconnecting');
      }
    });

    try {
      await hubConnection.start();
      this._isConnected = true;
      this._log("SignalR - succefully connected");
      this._hubConnectionSubject.next(hubConnection);
      return hubConnection;
    } catch (error) {
      console.error('SignalR - connection error: ', error);
      this._isConnected = false;
      this._hubConnectionSubject.next(null);
      return null;
    }
  }

  protected stopConnection() {
    this._disposed = true;
    this._isConnected = false;
    this._isConnecting = false;
    this._reconnected.complete();
    // this._disconnected.complete();
    this._reconnectionSubscription?.unsubscribe();
    // this._hubConnection?.onreconnected(() => { });
    // this._hubConnection?.onclose(() => { });
    // this._hubConnection?.onreconnecting(() => { });
    this._hubConnectionSubject.value?.stop().then(x => {
      this._hubConnectionSubject.next(null);
    });
  }

  private _reconnectManually() {
    if (this._reconnectionSubscription != null
      && !this._reconnectionSubscription?.closed) {
      return;
    }

    this._reconnectionSubscription = interval(this._intervalToReconnect)
      .pipe(
        takeUntil(this.reconnected$),
        map(x => this._hubConnectionSubject.value),
        filter(hubConnection => hubConnection == null
          || (hubConnection.state != HubConnectionState.Connected
            && hubConnection.state != HubConnectionState.Connecting)),
        tap(x => this._log('SignalR - trying to reconnecting')),
        exhaustMap(x => from(this.startConnection())),
        filter(x => x != null && x.state == HubConnectionState.Connected),
        catchError((err, obs) => {
          console.error(err);
          return obs;
        })
      )
      .subscribe(x => {
        this._reconnected.next();
        this._log('SignalR - manually reconnected');
      });
  }

  public registerNotification(notificationName: string, func: Function) {

  }

  // public joinToGroup(groupName: string, ...args: any[]) {
  //   return of(this._hubConnection?.send(groupName, args))
  // }

  // public leaveFromGroup(groupName: string, ...args: any[]) {
  //   return of(this._hubConnection?.send(groupName, args))
  // }

  // private getRandomIntInclusive(min: number, max: number) {
  //   min = Math.ceil(min);
  //   max = Math.floor(max);
  //   return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  // }

  private _log(message: string) {
    console.info(`%c${message}`, "padding: 5px 5px; background-color: #03679c; color: #d6d6d6")
  }
}
