import { DialogMessageType } from "./dialog-message-type";

export class DialogMessage{
    public type: DialogMessageType;
    public title: string | null;
    public message: string | null;
    public details: string | null;
    public url: string | null;
    public status: number | null;

    constructor() {
        this.type = DialogMessageType.INFO;
        this.title = null;
        this.message = null;
        this.details = null;
        this.url = null;
        this.status = null;
    }
}