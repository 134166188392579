<div class="min-w-[300px] max-w-[320px] md:min-w-[400px] md:max-w-[500px]">
  <div class="text-white p-[10px] border-b border-b-[#dee2e6]"
  [ngClass]="{'bg-[#cfe2ff]' : message.type == messageTypeEnum.INFO,
              'bg-[#e2e3e5]' : message.type == messageTypeEnum.QUESTION,
              'bg-[#d1e7dd]' : message.type == messageTypeEnum.SUCCESS,
              'bg-[#f8d7da]' : message.type == messageTypeEnum.ERROR,
              'bg-[#fff3cd]' : message.type == messageTypeEnum.WARNING,
              'bg-[#d3d3d4]' : message.type == null}">
    <h2 mat-dialog-title style="margin-bottom: 0px"
      [ngClass]="{'text-[#084298]' : message.type == messageTypeEnum.INFO,
                  'text-[#41464b]' : message.type == messageTypeEnum.QUESTION,
                  'text-[#0f5132]' : message.type == messageTypeEnum.SUCCESS,
                  'text-[#842029]' : message.type == messageTypeEnum.ERROR,
                  'text-[#664d03]' : message.type == messageTypeEnum.WARNING,
                  'text-[#141619]' : message.type == null}">{{message.title}}</h2>
  </div>
  <div class="p-[10px]">
    <mat-dialog-content>
      {{message.message}}
    </mat-dialog-content>
    <cdk-accordion class="example-accordion"
      *ngIf="message.url != null || message.status != null || message.details != null">
      <cdk-accordion-item #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header'" [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body'">
        <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
          <span class="example-accordion-item-description">
            Dettagli
          </span>
        </div>
        <div class="example-accordion-item-body" role="region" [style.display]="accordionItem.expanded ? '' : 'none'"
          [attr.id]="'accordion-body'" [attr.aria-labelledby]="'accordion-header'">
          <p *ngIf="message.url != null">Url: {{message.url}}</p>
          <p *ngIf="message.status != null">Status: {{message.status}}</p>
          <p *ngIf="message.details != null" class="break-words">Details: {{message.details}}</p>
        </div>
      </cdk-accordion-item>
    </cdk-accordion>
  </div>
  <div class="px-[10px] py-[20px]">
    <mat-dialog-actions align="end" *ngIf="message.type != messageTypeEnum.QUESTION">
      <!-- <button mat-button mat-dialog-close>Cancel</button> -->
      <button mat-raised-button [mat-dialog-close]="true">Ok</button>
    </mat-dialog-actions>
    <mat-dialog-actions *ngIf="message.type == messageTypeEnum.QUESTION">
      <div class="w-full flex">
        <div class="basis-1/2">
          <button mat-raised-button [mat-dialog-close]="false">{{'COMMON.NO' | translate}}</button>
        </div>
        <div class="basis-1/2 flex justify-end">
          <button mat-raised-button color="warn" [mat-dialog-close]="true">{{'COMMON.YES' | translate}}</button>
        </div>
      </div>
    </mat-dialog-actions>
  </div>
</div>
