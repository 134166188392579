import { Component, OnInit, Inject } from '@angular/core';
import { DialogMessage } from '../../models/dialog-message';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogMessageType } from '../../models/dialog-message-type';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
})
export class MessageComponent implements OnInit {
  //public message: Message | null = null;

  public messageTypeEnum = DialogMessageType;

  constructor(@Inject(MAT_DIALOG_DATA) public message: DialogMessage) {}

  ngOnInit(): void {
  }
}
