import { environment } from "src/environments/environment";

export const API_LOGIN = 'auth';
export const API_HANDLING = 'handling';
export const API_GRUPPI_PASSEGGERI_TICKET = 'gruppiPasseggeriTicket';
export const API_SERVIZI = 'richiesteservizi';
export const API_HOTEL = 'hotel';
export const API_TRASPORTI = 'trasporti';
export const API_ADMIN_LAYOVER = 'layoveradmin';
export const API_TICKET_ADMIN = 'ticketadmin';
export const API_ADMIN_COMPAGNIA_AEREA = 'airadmin';
export const API_SHARED = 'shared';
export const APP_SITO = '';
export const API_PASSEGGERI = 'passeggeri';
export const API_UTENZA = 'utenza';
export const APP_LOGIN = 'login';
export const APP_HANDLER = 'handler';
export const APP_SERVIZI = 'richiesteservizi';
export const APP_HOTEL = 'hotel';
export const APP_HOTAC = 'hotac';
export const APP_CAMERE = 'camere';
export const APP_TRASPORTI = 'trasporti';
export const APP_MEZZI = 'mezzi';
export const APP_AUTISTI = 'autisti';
export const APP_FATTURE = 'fatture';
export const APP_TELEFONI = 'telefoni';
export const APP_ORDINI = 'ordini';
export const APP_CONSUMI = 'consumi';
export const APP_ADMIN_LAYOVER = 'admin';
export const APP_TRASFERIMENTI = 'trasferimenti';
export const APP_PASSEGGERI = 'passeggeri';;
export const APP_FOOD_SERVICE_COMPANY_ADMIN = 'supplier-food-service-company-admin';
export const APP_FOOD_SERVICE_COMPANY_USER = 'food-service-company-user';
export const APP_CUSTOMER_AIRLINE_COMPANY_ADMIN = 'customer-airline-company';
export const APP_BED_BANK = 'bed-bank';
export const APP_BED_BANK_HOTEL = 'bed-bank-hotel';
export const APP_HOTAC_USER = 'hotac';
export const APP_QR_CODE = 'qrcode';
export const APP_LAYOVER_USERS = 'layover-users';
export const APP_ROOMS_SURVEY = 'rooms-survey';
export const APP_COOKIE_POLICY = 'cookie-policy';
export const REGISTRAZIONE_UTENTE_URL = 'iscrizione';
export const REGISTRAZIONE_UTENTE_DISCLAIMER = 'disclaimer';
export const REGISTRAZIONE_UTENTE_END = 'fine';
// export const USER_HANDLING = 'Handling';
// export const USER_HOTEL = 'Hotel';
// export const USER_TRASPORTI = 'Trasporti';
// export const USER_ADMIN_LAYOVER = 'AdminLayoverTech';
// export const USER_COMPAGNIA_AEREA = 'CompagniaAerea';
// export const USER_PASSEGGERO = 'Passeggero';
// Parametri gestione token
export const TOKEN_OBJECT_NAME = 'LayoverToken'; // Nome dell'oggetto nel localStorage che contiene il token  API_LOGIN = 'auth';
export const API_TEST = environment.BASE_URL + '/api/v1/test/';
export const API_TICKET = environment.BASE_URL + '/api/v1/ticket/';
export const API_SERVICE_REQUEST = environment.BASE_URL + '/api/v1/ServiceRequest/';
export const API_AIRLINE_COMPANY = environment.BASE_URL + '/api/v1/AirlineCompany/';
export const API_SUPPLIER_FOOD_SERVICE_COMPANY = environment.BASE_URL + '/api/v1/SupplierFoodServiceCompany/';
export const API_SERVICE_REQUEST_AIRPORT_TO_AIRPORT = environment.BASE_URL + '/api/v1/ServiceRequestAirportToAirport/';
export const API_SERVICE_REQUEST_AIRPORT_TO_HOTEL = environment.BASE_URL + '/api/v1/ServiceRequestAirportToHotel/';
export const API_SERVICE_REQUEST_HOTEL_TO_AEROPORTO = environment.BASE_URL + '/api/v1/ServiceRequestHotelToAirport/';
export const API_SERVICE_REQUEST_SNACK = environment.BASE_URL + '/api/v1/ServiceRequestSnack/';
export const API_LANGUAGE_CULTURE = environment.BASE_URL + '/api/v1/languageCulture/';
export const API_USER = environment.BASE_URL + '/api/v1/user/';
export const API_AIRPORT = environment.BASE_URL + '/api/v1/airport/';
export const API_TRANSPORT = environment.BASE_URL + '/api/v1/Transport/';
export const API_TRANSPORT_ORDER = environment.BASE_URL + '/api/v1/TransportOrder/';
export const API_TRANSPORT_ORDER_ITEM = environment.BASE_URL + '/api/v1/TransportOrderItem/';
export const API_VOUCHER_SNACK = environment.BASE_URL + '/api/v1/VoucherSnack/';
export const API_GRUPPI_PASSEGGERI = environment.BASE_URL + '/api/v1/PassengerGroup/';
