import { Component } from '@angular/core';
import { SignalRService } from './core/services/signal-r.service';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Layover Tech Solutions';

  constructor(
    private readonly _signalR: SignalRService,
    private readonly _title: Title
  ) {
    this._signalR.startConnection();
    this._initialize();
  }

  private _initialize(){
    let title = `${environment.APP_NAME}`;
    if (environment.ENV != null) {
      title += ` - ${environment.ENV}`;
    }
    this._title.setTitle(title);
  }

}
