import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { appReducer } from "./app.reducer";

@NgModule({
  imports: [
    StoreModule.forRoot(
      {
        'app': appReducer
      }, { metaReducers: [] }),
  ],
  // providers: [
  //   {
  //     provide: USER_PROVIDED_META_REDUCERS,
  //     deps: [LocalStorageService],
  //     useFactory: getMetaReducers
  //   },
  // ],
})
export class AppStateModule { }

// export function getMetaReducers(localStorage: LocalStorageService): MetaReducer<AppState>[] {
//   return [
//     storeLoggedUserMetaReducer(localStorage),
//     storeRecentLoggedUsersMetaReducer(localStorage),
//     //storeSessionInfoMetaReducer(localStorage)
//   ]
// }