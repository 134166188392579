import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleApiService {

  private _loadedSubject: BehaviorSubject<boolean>;

  public get loadedSubject$() { return this._loadedSubject.asObservable(); }

  constructor() {
    this._loadedSubject = new BehaviorSubject<boolean>(false);
  }

  public initialize(googleKey: string, cultureCode: string) {
    (<any>window).googleMapsApiLoaded = () => {
      (google.maps.importLibrary("marker")).then(x=>{
        this._loadedSubject.next(true);
      });
    };

    let scriptElement = document.createElement('script');
    let googleMapsLink = "https://maps.googleapis.com/maps/api/js?key="
      + googleKey
      + "&callback=googleMapsApiLoaded&libraries=places,geometry&language="
      + cultureCode;
    scriptElement.setAttribute('id', 'googleMapsApi');
    scriptElement.setAttribute('src', googleMapsLink);
    scriptElement.setAttribute('defer', '');
    scriptElement.setAttribute('type', 'text/javascript');
    scriptElement.setAttribute('async', 'true');
    document.getElementsByTagName('head')[0].appendChild(scriptElement);
  }
}
