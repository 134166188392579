import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { of, tap } from "rxjs";
import { EnumListQueryResponse } from "../autogenerated/model.autogenerated";
import { TaggedTemplateExpr } from "@angular/compiler";

@Injectable({
  providedIn: 'root'
})
export class EnumsService {

  private _enums: EnumListQueryResponse | null = null;

  public get enums() { return this._enums; }

  constructor(private readonly _api: ApiService) {

  }

  public initialize() {
    return this._api.Enum_ListAll()
      .pipe(
        tap(x => this._enums = x)        
      )
  }
}