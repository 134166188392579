import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { SelettoreLinguaComponent } from './selettore-lingua/selettore-lingua.component';


@NgModule({
  declarations: [
    SelettoreLinguaComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
  ],
  exports: [
    SelettoreLinguaComponent
  ]
})
export class LinguaModule { }
