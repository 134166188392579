import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { ApiService } from './api.service';
import { Airport } from '../autogenerated/model.autogenerated';

@Injectable({
  providedIn: 'root',
})
export class CacheAirportsService {

  public airports: Airport[] = [];

  constructor(
    private readonly _api: ApiService
  ) {

  }

  public initialize() {
    return this._api.Airport_List()
      .pipe(
        tap(x => {
          this.airports = x.items!;
        })
      );
  }

  public filterAirports(filter: string) {
    return this.airports.filter(x => 
      x.name!.toLowerCase().includes(filter.toLowerCase())
      || x.iataCode!.toLowerCase().startsWith(filter.toLowerCase()));
  }
}
