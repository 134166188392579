import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of, catchError, throwError, Observable } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { LanguageCulture, Result } from '../autogenerated/model.autogenerated';
import { ApiService } from './api.service';
import * as moment from 'moment-timezone';
import { LegacyDateAdapter } from '@angular/material/legacy-core';
import { DateAdapter } from '@angular/material/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {

  private _currentLanguageSubject = new BehaviorSubject<LanguageCulture | null>(null);
  private _supportedLanguages: LanguageCulture[] = [];

  private _ianaName: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  public get currentLanguage$() { return this._currentLanguageSubject; }
  public get currentLanguage() { return this._currentLanguageSubject.value; }
  public get supportedLanguages() { return this._supportedLanguages; }

  constructor(
    private readonly _translate: TranslateService,
    private readonly _api: ApiService,
    private readonly _user: UserService,
    private readonly _legacyDateAdapter: LegacyDateAdapter<Date>,
    private readonly _dateAdapter: DateAdapter<Date>

  ) {

  }

  public initialize(userLanguage: LanguageCulture | null): Observable<LanguageCulture> {
    return this._api.LanguageCulture_List()
      .pipe(
        tap(x => this._supportedLanguages = x.items!),
        map(x => {
          if (userLanguage != null && userLanguage.languageCultureName != null) {
            return userLanguage.languageCultureName;
          } else {
            return navigator.language;
          }
        }),
        map(x => this._checkAndGetLanguageCulture(x)),
        exhaustMap(language => {
          if (userLanguage == null || language.languageCultureName != userLanguage.languageCultureName) {
            //x è la lingua da impostare all'utente
            return this._updateUserLanguage(language)
              .pipe(
                map(x => language)
              );
          } else {
            return of(null)
              .pipe(
                map(x => language)
              );
          }
        }),
        tap(language => this._setUserLanguage(language)),
        catchError((err, obs) => of())
      );
  }

  public now() {
    return moment.tz(this._ianaName);
  }

  public dateNowTime(date: moment.Moment) {
    let now = this.now();
    now = now.set('date', date.date());
    now = now.set('month', date.month());
    now = now.set('year', date.year());
    return now;
  }

  public setUserLanguage(languageCulture: LanguageCulture) {
    languageCulture = this._checkAndGetLanguageCulture(languageCulture.languageCultureName!);
    return this._updateUserLanguage(languageCulture)
      .pipe(
        tap(x => this._setUserLanguage(languageCulture))
      );
  }

  private _setUserLanguage(languageCulture: LanguageCulture) {
    moment.locale(languageCulture.languageCultureName?.substring(0, 2));
    this._legacyDateAdapter.setLocale(languageCulture.languageCultureName);
    this._dateAdapter.setLocale(languageCulture.languageCultureName);
    this._currentLanguageSubject.next(languageCulture);
    this._translate.use(languageCulture.languageCultureName!);
  }

  private _checkAndGetLanguageCulture(languageCultureName: string) {
    let languageCulture = this._findLanguageCulture(languageCultureName);

    if (languageCulture == null) {
      console.warn(`Language - unsupported language ${languageCultureName}`);
      //la lingua da impostare non è supporta percio leggo quella di default
      languageCulture = this._findLanguageCulture(environment.DEFAULT_LANGUAGE);
    }

    console.info(`Language - setting user language to ${languageCultureName}`);

    if (languageCulture == null) {
      const errorMessage = `Language - error unable to find default language ${languageCultureName}`;
      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    return languageCulture;
  }

  private _updateUserLanguage(languageCulture: LanguageCulture) {
    let language = this._findLanguageCulture(environment.DEFAULT_LANGUAGE);
    if (this._user.idUser == null) {
      this._currentLanguageSubject.next(language ?? null);
      this._translate.use(environment.DEFAULT_LANGUAGE);
      return of(<Result>{ isSuccess: true });
    } else {
      return this._api.User_UpdateLanguageCulture(languageCulture?.idLanguageCulture!)
        .pipe(
          catchError((err, obs) => {
            const message = `Language - Error during update language. Current language was set to default language ${environment.DEFAULT_LANGUAGE}`
            this._currentLanguageSubject.next(language ?? null)
            this._translate.use(environment.DEFAULT_LANGUAGE);
            console.error(message);
            return throwError(() => new Error(message));
          })
        );
    }
  }

  private _findLanguageCulture(languageCultureName: string) {
    return this._supportedLanguages.find(x => x.languageCultureName == languageCultureName);
  }

}
